<template>
  <div class="contact">
    <div class="banner-box">
      <div class="banner" v-if="contactData.banners">
        <img :src="contactData.banners[0].bgUrl" />
        <span>{{$store.state.lang === 'zh' ? contactData.banners[0].titleLangZh : contactData.banners[0].title}}</span>
      </div>
    </div>
    <div class="empty-box">
      <div class="container">
        <div class="content" >
          <h3  style="white-space: initial">{{$store.state.lang=='zh'?contactData.officeLangZh:contactData.office}}</h3>
          
          <span  style="white-space: initial">
            
            <i style="width: 250px"> {{$store.state.lang=='zh'?contactData.addressLangZh:contactData.address}} </i>
            <i style="width: 260px" v-if="$store.state.lang !='zh'">{{contactData.email}}</i>
          </span>
          <!-- <span v-if="$store.state.lang !='zh'">{{contactData.email}}</span> -->
          <!-- <span>
            <i>No. 1717,West Nanjing Road, Jing’an</i>
            <i>District, Shanghai, China</i>
          </span> -->
          <span>{{$store.state.lang=='zh'?'电话':'Telephone'}}：{{contactData.phone}}</span>
          <span>{{$store.state.lang=='zh'?'传真':'Fax'}}：{{contactData.fax}}</span>
          <span>{{$store.state.lang=='zh'?'邮箱':'Email'}}：info@cvpcap.com</span>
          <div style="width: 40%;margin: 0 auto auto 48px;"><img src="https://cvp-new-version.oss-accelerate.aliyuncs.com/8a26151c82866c580ddf6a8cb561f5d.jpg" width="100%" alt=""></div>
        </div>
        <baidu-map
          class="map-view"
          :center="center"
          :zoom="15"
          :scroll-wheel-zoom="true"
        >
          <bm-navigation anchor="BMAP_ANCHOR_TOP_LEFT"></bm-navigation>
          <bm-overlay pane="labelPane" @draw="draw" class="sample">
            <div class="sample-content">
              <span>ClearVue Partners</span>
              <span>{{$store.state.lang=='zh'?'电话':'Tel'}}: +86(21)50318996</span>
            </div>
          </bm-overlay>
        </baidu-map>
      </div>
    </div>
  </div>
</template>

<script>
import { BaiduMap } from "vue-baidu-map";

export default {
  components: {
    "baidu-map": BaiduMap,
  },
  data() {
    return {
      uri: require("./bg.png"),
      // center: { lng: 121.451076, lat: 31.228003},
      center: { lng: 121.43943, lat: 31.20663},
    };
  },
  computed:{
    contactData(){
      const contact = this.$store.state.mainContent.contact
      const lang = this.$store.state.lang
      let obj = {}
      if(contact && contact.extend){
       
        obj = {
          ...contact,
          ...JSON.parse(contact.extend)
        }

      }
      return obj
    }
  },
  mounted() {
    console.log(BaiduMap);
  },
  methods: {
    draw({ el, BMap, map }) {
      const pixel = map.pointToOverlayPixel(new BMap.Point(121.43943,31.20663));
      el.style.left = pixel.x - 85 + "px";
      el.style.top = pixel.y - 55 + "px";
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("../../assets/custom.scss");

.banner-box {
  padding-top: 106px;
  .banner {
    position: relative;
    img {
      width: 100%;
    }
    span {
      position: absolute;
      color: #fff;
      font-size: 50px;
      z-index: 1;
      top: 50%;
      transform: translate(0, -50%);
      right: 75px;
    }
  }
}

.empty-box {
  width: 100%;
  height: 500px;
  position: relative;
}

.container {
  position: absolute;
  top: -80px;
  left: 50%;
  transform: translate(-50%, 0);
  opacity: 1;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(9, 1, 4, 0.29);
  display: flex;
  padding: 50px 45px 45px 50px;

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 30%;
    h3 {
      // margin-bottom: 30px;
      line-height: 30px;
    }
    span {
      color: #7d7d7d;
      display: block;
      line-height: 30px;
    }
    i {
      display: block;
      font-style: normal;
    }
  }
  .map-view {
    flex: 1;
    height: 400px;
  }
}

.sample {
  position: absolute;
  &-content {
    background-color: rgb(35, 82, 124);
    border-color: rgb(35, 82, 124);
    color: rgb(255, 255, 255);
    border-radius: 4px;
    padding: 3px 50px 3px 10px;
    font-size: 12px;
    position: relative;
    &:after {
      position: absolute;
      top: 100%;
      left: 50%;
      border: solid transparent;
      content: "";
      size: 0 0;
      pointer-events: none;
      border-top-color: inherit;
      border-width: 14px 8px;
      margin-left: -6px;
    }
    span {
      white-space: nowrap;
      display: block;
    }
    
  }
}
</style>
